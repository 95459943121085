<template>
  <div class="bg-grey mt-4 pt-5 pb-5">
    <b-container fluid class="customized-form">
      <div>
        <h4 class="font-weight-bold pb-4">Welcome, User</h4>
      </div>
      <b-row class="mx-md-2 user-onboard applicant-onboard">
        <div class="header col-md-12 py-3 border-bottom">
          <div class="step-heading">
            <figure>
              <img src="@/assets/app-icons/cv.svg" class="img-fluid pr-3" alt="cv-icon" width="50px" />
            </figure>
            <div class="pt-2">
              <h4 class="step-name">Add Videos</h4>
            </div>
          </div>
        </div>
        <div class="content col-md-12">
          <div class="d-flex justify-content-between align-items-center py-2">
            <h4 class="font-weight-bold">My Videos</h4>
            <!-- <ul class="profile-slider user-slides slides-arrow d-flex">
              <li class="prev"><i class="fa fa-angle-left"></i></li>
              <li class="next"><i class="fa fa-angle-right"></i></li>
            </ul> -->
          </div>
          <div class="row align-items-center video-slider-arrow">
            <div :class="videos.length == 1 ? 'col-md-5' : 'col-md-8 px-md-5 mx-3'" v-if="videos.length != 0">
              <slick ref="slick" :options="videoSlider" v-if="videos.length > 0">
                <div @click="onClick(v)" v-for="(v, index) in videos" :key="index" class="position-relative">
                  <img :src="`https://videodelivery.net/${v.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`" style="border: none; cursor: pointer" height="300" width="95%" />
                  <img src="@/assets/app-icons/orange-video.svg" class="img-center video-play-img" />
                </div>
              </slick>
            </div>
            <div class="col-md-3">
              <div class="add-video-section" style="cursor: pointer" @click="addVideo">
                <h2 class="font-weight-bold">ADD VIDEO +</h2>
              </div>
            </div>
          </div>
          <div>
            <ValidationObserver ref="videoInput" tag="div" class="row py-5">
              <div class="col-md-6 col-12">
                <div class="video-section">
                  <div class="thumbnail">
                    <div v-show="recordingOn">
                      <video id="myVideo" playsinline class="video-js vjs-default-skin">
                        <p class="vjs-no-js">
                          To view this video please enable JavaScript, or consider upgrading to a web browser that
                          <a href="https://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video. </a>
                        </p>
                      </video>
                      <br />
                      <div class="d-flex py-3">
                        <button type="button" class="btn btn-info mx-2" @click.prevent="startRecording()" v-bind:disabled="isStartRecording" id="btnStart">Start Recording</button>
                        <button type="button" class="btn btn-danger mr-2" @click.prevent="finishRecording()">Stop Recording</button>
                        <button type="button" class="btn btn-success mr-2" @click.prevent="submitVideo()" v-bind:disabled="isSaveDisabled" id="btnSave">
                          {{ submitText }}
                        </button>
                        <button type="button" class="btn btn-primary" @click.prevent="retakeVideo()" v-bind:disabled="isRetakeDisabled" id="btnRetake">Retake</button>
                      </div>

                      <!-- <button
                        class="btn btn-light"
                        @click="
                          () => {
                            recordingOn = false;
                          }
                        "
                      >
                        Back
                      </button> -->
                    </div>
                    <!-- :src="
                        `https://iframe.videodelivery.net/${
                          selectedVideo
                            ? selectedVideo.user_job_video_path
                            : videos[0].user_job_video_path
                        }/thumbnails/thumbnail.jpg?time=1s&height=500`
                      " -->
                    <iframe
                      v-if="!recordingOn && videos.length > 0"
                      :src="`https://iframe.videodelivery.net/${selectedVideo ? selectedVideo.user_job_video_path : videos[0].user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=500`"
                      style="border: none"
                      height="300"
                      width="100%"
                      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                      allowfullscreen="true"
                      id="stream-player1"
                    ></iframe>

                    <!-- <img src="https://img.youtube.com/vi/tE_lupFPrAg/0.jpg" /> -->
                  </div>
                  <div class="action-icons" v-if="!recordingOn && !playVideo">
                    <div
                      @click="
                        () => {
                          if (videos.length > 0) {
                            playVideo = true;
                          }
                        }
                      "
                    >
                      <figure>
                        <img src="@/assets/app-icons/icon-play.svg" />
                      </figure>
                      <p>PLAY</p>
                    </div>
                    <div @click="onDeleteVideo">
                      <figure>
                        <img src="@/assets/app-icons/icon-delete.svg" />
                      </figure>
                      <p>DELETE</p>
                    </div>
                    <div @click="getUploadUrl">
                      <figure>
                        <img src="@/assets/app-icons/icon-record.svg" />
                      </figure>
                      <p>RE-RECORD</p>
                    </div>

                    <!-- <div>
                      <figure>
                        <img src="@/assets/app-icons/icon-upload.svg" />
                      </figure>
                      <p>RE-UPLOAD</p>
                    </div> -->
                  </div>

                  <!-- <button class="btn btn-delete" @click="onDeleteVideo">
                  Delete Video
                </button> -->
                  <div class="thumbnail-heading">
                    <!-- <h5 class="fpn-b">Video Profile #1</h5>
                    <h6 class="f-light fs-14">2m 30s</h6> -->
                  </div>
                </div>
                <!--End Video Section-->
              </div>
              <div class="col-md-6 col-12">
                <ValidationProvider name="video_title" rules="required" v-slot="{ errors, classes }" tag="div">
                  <b-form-input placeholder="Video Title" :class="classes" v-model="video_title"></b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="video_desc" rules="required" v-slot="{ errors, classes }" tag="div">
                  <b-form-textarea placeholder="Video Description (Max 100 words)" rows="8" max-rows="10" class="w-100" :class="classes" v-model="video_description" :state="video_description ? video_description.length <= 150 : null"></b-form-textarea>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <!-- <div class="drag-drop text-center">
                  <div class="custom-upload">
                    <div class="m-3">
                      <video ref="video" style="display: none"></video>
                      <canvas ref="canvas" style="width: 100px"></canvas>
                    </div>
                    <label for="video" class="btn reupload-btn">
                      <img
                        src="@/assets/app-icons/upload.svg"
                        class="img-fluid mr-3"
                        alt="upload-icon"
                        width="20px"
                      />

                      {{ fileSelected != "" ? fileSelected : "Choose File" }}
                    </label>

                    <input
                      type="file"
                      accept="video/*"
                      id="video"
                      @change="onFileChange"
                    />
                    <br /><br />
                  </div>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-video-upload btn-primary"
                    @click="onUpload"
                  >
                    Upload Video
                  </button>
                </div> -->
              </div>
            </ValidationObserver>
          </div>
        </div>
        <div class="footer col-md-12 border-top">
          <div>
            <button class="btn btn-previous mr-5" @click="$router.go(-1)">BACK</button>

            <button @click="onReset" class="btn btn-reset font-weight-bold mr-5">RESET</button>
            <button class="btn btn-next font-weight-bold" @click="$router.push({ name: 'Applicant-Profile' })">SAVE</button>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import GetVideoUploadUrl from "@/apiServices/GetVideoUploadUrl";
import { PhotoCapture, VideoCapture } from "vue-media-recorder";
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import axios from "axios";
import RadialProgressBar from "vue-radial-progress";

import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
import FFmpegjsEngine from "videojs-record/dist/plugins/videojs.record.ffmpegjs.js";
// import vue2Dropzone from "vue2-dropzone";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";

import "@/assets/css/video.css";

export default {
  components: { PhotoCapture, VideoCapture, RadialProgressBar },
  data() {
    return {
      iframe: null,
      player: "",
      retake: 0,
      video_status: "update",
      archived: null,
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      submitText: "Submit",
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          deviceButton: false,
          recordToggle: false,
          pipToggle: false,
        },
        width: 500,
        height: 300,
        fluid: true,
        plugins: {
          record: {
            pip: false,
            audio: true,
            video: true,
            maxLength: 900,
            debug: true,
          },
        },
      },

      ///////////////////////////////////
      recordingOn: false,
      playVideo: false,
      completedSteps: 0,
      totalSteps: 100,
      showRadial: false,
      url: "",
      fileSelected: "",
      selectedVideo: null,
      slidesShow: 2,
      subs: null,
      //videos: [1, 2, 3, 4, 5],
      videos: [],
      loading: false,
      startCamera: false,
      imageBase64: null,
      videoUrl: null,
      video_description: null,
      video_title: null,
      video_length: null,
      videoInput: null,
      videoSlider: {
        arrows: true,
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "02%",
        centerMode: true,

        // variableWidth: true,
        // centerMode: true,
        // prevArrow: ".prev",
        // nextArrow: ".next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },

  beforeMount() {
    this.getApplicantProfile();
    this.getAllVideos();

    console.log(this.videos);
    console.log(this.videoSlider);
  },
  mounted: function () {
    this.getUserSubs();
    this.getUploadedVideos();

    console.log("Videos Slider", this.videos);
  },
  beforeRouteLeave(to, from, next) {
    console.log("to:::::::::::: :>> ", to);
    console.log("from::::::::::: :>> ", from);
    if (to.name != "Applicant-Onboarding-1" && to.name != "Applicant-Onboarding-2" && to.name != "Applicant-Onboarding-3") {
      this.$store.commit("RESET_PREFILLED_FLAG");
      this.$store.commit("RESET_PREFILLED_FLAG2");
      next();
    } else next();
  },
  methods: {
    addVideo() {
      if (!this.subs) {
        this.$toasted.show("You dont have any plan", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      this.video_status = "add";
      this.video_title = null;
      this.video_description = null;
      this.getUploadUrl();
    },
    // updateVideo() {
    //   this.video_status = "update";
    //   if (this.selectedVideo) {
    //     this.recordingOn = true;
    //     this.configPlayer();
    //   } else {
    //     this.recordingOn = false;
    //   }
    // },
    async getUploadUrl() {
      if (!this.subs) {
        this.$toasted.show("You dont have any plan", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      // if (this.videos.length == 0) {
      //   this.$toasted.show("Add video first!", {
      //     duration: 6000,
      //     icon: "clear",
      //   });
      //   return;
      // }
      let loader = this.$loading.show();
      if (this.videos.length < +this.subs.subs_pkg_no_of_videos || this.video_status == "update") {
        try {
          const res = await GetVideoUploadUrl.GetVideoUploadUrl();
          this.url = res.data.data.result.uploadURL;
          //  this.dropzoneOptions.url = this.url;
          this.loading = false;
          this.recordingOn = true;
          this.configPlayer();

          this.showRadial = false;
          loader.hide();
        } catch (error) {
          
          loader.hide();
          this.recordingOn = false;
          this.$toasted.show(
            `Internal Server Error.
              Video May not upload.
              please Reaload this page`,
            {
              duration: 5000,
              icon: "clear",
            }
          );
        }
      } else {
        loader.hide();
        if (this.subs.subs_pkg_no_of_videos > 0) {
          this.$toasted.show(
            `You have uploaded all ${this.subs.subs_pkg_no_of_videos} videos.
      please upgrade your plan`,
            { duration: 6000, icon: "clear" }
          );
        } else {
          this.$toasted.show(`You don't have plan to upload a video`, {
            duration: 6000,
            icon: "clear",
          });
        }
      }
    },

    finishRecording() {
      this.player.record().stopDevice();
    },
    onClickVideo() {
      console.log("Video pause or end");
      this.recordingOn = false;
      this.playVideo = false;
    },
    configPlayer() {
      if (this.player === "") {
        this.player = videojs("myVideo", this.options, () => {
          // print version information at startup
          var msg = "Using video.js " + videojs.VERSION + " with videojs-record " + videojs.getPluginVersion("record") + " and recordrtc " + RecordRTC.version;
          videojs.log(msg);
        });
        // error handling
        this.player.on("deviceReady", () => {
          this.player.record().start();
          console.log("device ready:");
        });
        this.player.on("deviceError", () => {
          console.log("device error:", this.player.deviceErrorCode);
        });
        this.player.on("error", (element, error) => {
          console.error(error);
        });
        // user clicked the record button and started recording
        this.player.on("startRecord", () => {
          console.log("started recording!");
        });
        // user completed recording and stream is available
        this.player.on("finishRecord", () => {
          this.isSaveDisabled = false;
          if (this.retake == 0) {
            this.isRetakeDisabled = false;
          }
          // the blob object contains the recorded data that
          // can be downloaded by the user, stored on server etc.
          console.log("finished recording: ", this.player.recordedData);
        });
      } else {
        console.log("player is not initialise", this.player);
      }
    },

    startCam(toggal) {
      this.startCamera = !toggal;
    },
    onFileChange(e) {
      let f = e.target.files[0];
      if (f.type == "video/mp4") {
        this.videoInput = e.target.files[0];
        this.fileSelected = "File Selected";
        console.log("this.videoInput :>> ", this.videoInput);

        //preview
        //preview videoInput

        var file = e.target.files[0];
        var fileReader = new FileReader();
        var x = this;
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = x.$refs["video"];
          console.log("video :>> ", video);
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = function () {
            var canvas = x.$refs["canvas"];
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            x.previewImage2 = canvas.toDataURL();
            console.log(" x.previewImage2 :>> ", x.previewImage2);
            //this.previewImage = x.previewImage2;
            var success = image.length > 100000;
            if (success) {
              // var img = x.$refs["img"];
              // img.src = image;
              // x.$refs["video-thumbnail"].appendChild(img);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);

        this.$toasted.show("File Selected", { duration: 6000, icon: "check" });
      } else {
        this.$toasted.show("file format not supported!", {
          duration: 6000,
          icon: clear,
        });
      }
    },
    uploadSuccess(file, response) {
      console.log("File Successfully uploaded", response.file);
    },
    uploadError(file, msg) {
      console.log("File Error Occured");
    },
    async getAllVideos() {
      const payload = { user_id: this.$cookies.get("user_id") };
      try {
        const getVideos = await ApplicantProfileService.GetUploadedVideos(payload);

        this.videos = getVideos.data.data;

        if (this.videos.length > 0) {
          this.selectedVideo = this.videos[0];
          this.video_status = "update";
        } else {
          this.video_status = "add";
        }

        if (this.videos.length == 1) {
          this.videoSlider.slidesToShow = 1;
        } else {
          this.videoSlider.slidesToShow = 2;
        }

        if (this.videos.length > 0 && this.$refs.slick) {
          this.$refs.slick.destroy();
          this.$nextTick(() => {
            this.$refs.slick.create();
            this.$refs.slick.goTo(0, true);
          });
        }
        console.log("videos------ :>> ", this.video);
      } catch (error) {
        
      }
    },
    getApplicantProfile() {
      const applicant_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      ApplicantProfileService.getApplicantProfile({ applicant_id })
        .then((data) => {
          console.log("data->", data);
          let profileImage = {
            image: data.data.data.user_data[0].user_job_profile_image,
          };
          console.log("profileImage :>> ", profileImage);
          this.$store.dispatch("onSetProfileImage", profileImage);
          loader.hide();
        })
        .catch((err) => {
          
          loader.hide();
        });
    },
    onReset() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you parmanently want to delete all videos", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          let loader = this.$loading.show();
          if (value) {
            ApplicantProfileService.deleteAllVideos().then((res) => {
              this.$toasted.show("All Videos Delete Successfully!", {
                duration: 6000,
                icon: "check",
              });
              loader.hide();
              this.getAllVideos();
            });
          } else {
            loader.hide();
            return;
          }
        })
        .catch((err) => {
          
        });
    },
    onUpload() {
      this.$refs.videoInput.validate().then(async (result) => {
        if (result) {
          try {
            if (this.videos.length < this.subs.subs_pkg_no_of_videos) {
              if (this.videoInput.type == "video/mp4") {
                if (this.videoInput.size <= 104857600) {
                  this.loading = true;
                  this.$bvModal.show("myModel");
                  this.showRadial = true;
                  const formData = new FormData();
                  formData.append("file", this.videoInput);
                  // const uploadResult = await fetch(this.url, {
                  //   method: "POST",
                  //   body: formData,
                  // });
                  // console.log("uploadResult :>> ", uploadResult);
                  // this.videoUrl = uploadResult.url;
                  axios
                    .post(this.url, formData, {
                      onUploadProgress: function (progressEvent) {
                        this.completedSteps = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
                      }.bind(this),
                    })
                    .then((uploadResult) => {
                      console.log("uploadResult :>> ", uploadResult);

                      this.videoUrl = uploadResult.config.url;
                      this.onSubmit();
                    })
                    .catch((error) => {
                      
                      this.completedSteps = 0;
                    });
                } else {
                  this.$toasted.show("video size should be less than 100 mb", {
                    duration: 6000,
                    icon: "clear",
                  });
                  this.loading = false;
                  this.$bvModal.hide("myModel");
                  this.showRadial = false;
                }
              } else {
                this.$toasted.show("Upload video with mp4 format!", {
                  duration: 6000,
                  icon: "clear",
                });
                return;
              }
            } else {
              if (this.subs.subs_pkg_no_of_videos.length > 0) {
                this.$toasted.show("You have uploaded upto the max limit", {
                  duration: 6000,
                  icon: "clear",
                });
              } else {
                this.$toasted.show("You don't have plan to upload a video", {
                  duration: 6000,
                  icon: "clear",
                });
              }
              setTimeout(() => {
                this.$router.push({ name: "Applicant-Profile" });
              }, 1000);
            }
          } catch (error) {
            
          }
        } else {
          this.$toasted.show("Enter required fields", {
            duration: 6000,
            icon: "clear",
          });
        }
      });
    },
    getUserSubs() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      ApplicantProfileService.getUserSubscription(user_id).then((res) => {
        this.subs = res.data.data[0];
        loader.hide();
      });
    },
    getUploadedVideos() {
      const user_id = this.$cookies.get("user_id");
      let loader = this.$loading.show();
      ApplicantProfileService.GetUploadedVideos({ user_id })
        .then((res) => {
          this.videos = res.data.data;
          loader.hide();
          this.iframe = Stream(document.getElementById("stream-player1"));
          console.log("this.iframe :>> ", this.iframe);
          this.iframe.addEventListener("play", () => {
            this.playVideo = true;
            this.recordingOn = false;
          });
          this.iframe.play().catch(() => {
            console.log("playback failed, muting to try again");
            this.iframe.muted = true;
            this.iframe.play();
          });
          this.iframe.addEventListener("pause", () => {
            this.playVideo = false;
            this.recordingOn = false;
          });
        })
        .catch((err) => {
          loader.hide();
        });
    },
    onClick(e) {
      console.log("e :>> ", e);
      this.selectedVideo = e;
      this.iframe = Stream(document.getElementById("stream-player1"));
      console.log("this.iframe :>> ", this.iframe);
      this.iframe.addEventListener("pause", () => {
        console.log("pause!");
        this.playVideo = false;
        this.recordingOn = false;
      });
      this.playVideo = false;
      this.recordingOn = false;
      this.video_status = "update";
      this.video_title = e.user_job_video_title;
      this.video_description = e.user_job_video_description;
    },
    onSubmit() {
      console.log("this.videoUrl :>> ", this.videoUrl);
      const payload = {
        role_id: this.$cookies.get("role_id"),
        user_id: this.$cookies.get("user_id"),
        video_title: this.video_title,
        video_status: this.video_status,
        video_id: this.selectedVideo ? this.selectedVideo.user_job_video_id : null,
        video_description: this.video_description,
        video_path: this.videoUrl.split("/")[3].split("-")[0],
        video_length: this.video_length,
      };
      

      if (payload.video_path) {
        ApplicantProfileService.uploadVideo(payload).then((res) => {
          
          this.videoUrl = null;
          this.fileSelected = "";
          this.$toasted.show("Your video uploaded succefully!", {
            position: "top-right",
            duration: 6000,
            icon: "check",
          });
          this.loading = false;
          this.$bvModal.hide("myModel");
          this.showRadial = false;
          this.getAllVideos();
          this.recordingOn = false;
          // this.player.dispose();
          this.player.record().stopDevice();
          this.player.record().reset();
          this.isStartRecording = false;
          this.submitText = "Submit";
          this.isSaveDisabled = true;
          // this.getUploadUrl();
          //this.$router.push({ name: "Applicant-Profile" });
        });
      } else {
        this.loading = false;
        this.player.record().stopDevice();
        this.isStartRecording = false;
        this.submitText = "Submit";
        this.isSaveDisabled = true;
        this.$bvModal.hide("myModel");
        this.showRadial = false;
        this.$toasted.show("Video not recorder!", {
          position: "top-right",
          duration: 6000,
          icon: "clear",
        });
        return;
      }
    },

    onDeleteVideo() {
      if (this.selectedVideo) {
        const video_id = this.selectedVideo.user_job_video_id;

        this.$bvModal
          .msgBoxConfirm("Do You Want to delete this video", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            let loader = this.$loading.show();
            if (value) {
              ApplicantProfileService.deleteVideo({ video_id }).then((res) => {
                this.$toasted.show("Video Delete Successfully!", {
                  duration: 6000,
                  icon: "check",
                });
                loader.hide();
                this.getAllVideos();
              });
            } else {
              loader.hide();
              return;
            }
          })
          .catch((err) => {
            
            loader.hide();
          });
      } else {
        this.$toasted.show("Select Video", { duration: 6000 });
      }
    },

    /////////////////////////////VideoJs Config///////////////
    startRecording() {
      this.isStartRecording = true;
      this.player.record().getDevice();
    },
    submitVideo() {
      this.$refs.videoInput.validate().then((result) => {
        if (result) {
          this.isSaveDisabled = true;
          this.isRetakeDisabled = true;
          var data = this.player.recordedData;
          var formData = new FormData();
          formData.append("file", data, data.name);
          this.submitText = "Uploading " + data.name;
          console.log("uploading recording:", data.name);
          this.player.record().stopDevice();
          this.$bvModal.show("myModel");
          axios
            .post(this.url, formData, {
              onUploadProgress: function (progressEvent) {
                this.videoUploaded = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
              }.bind(this),
            })
            .then((uploadResult) => {
              console.log("uploadResult :>> ", uploadResult);
              this.videoUrl = uploadResult.config.url;
              this.onSubmit();
            })
            .catch((error) => {
              
              this.videoUploaded = 0;
            });
        } else {
          this.$toasted.show("please write video title and description", {
            duration: 6000,
            icon: "clear",
          });
          return;
        }
      });
      // fetch(this.uploadUrl, {
      //   method: "POST",
      //   body: formData,
      //   headers: {
      //     "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      //   },
      // })
      //   .then((success) => {
      //     console.log("recording upload complete.");
      //     this.submitText = "Upload Complete";
      //   })
      //   .catch((error) => {
      //     console.error("an upload error occurred!");
      //     this.submitText = "Upload Failed";
      //   });
    },
    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.retake += 1;
      this.player.record().start();
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
.video {
  width: 500% !important;
}
.applicant-onboard .btn-video-upload {
  font-family: "pn-regular";
  font-size: 15px;
  letter-spacing: 1.5px;
}

.add-video-section {
  background-color: #eaeaea;
  height: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
  border-radius: 08px;
}

.slides-arrow li {
  list-style-type: none;
}
.slides-arrow li .fa {
  font-size: 30px;
  color: black;
  cursor: pointer;
  padding-right: 30px;
}
</style>
